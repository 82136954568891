import { addParamsToUrl } from "../../utils/helpers";
import urls from "../../api/urls";
import { useState } from "react";
import useAPI from "./useAPI";
import store from "../../utils/store";

function useMenu(props) {
  const [price, setPrice] = useState(null);
  // eslint-disable-next-line
  const { loading, post, get, put, patch, fdelete } = useAPI();

  const getCost = async (region, menuId, extraId) => {
    // return { cost: 0 };
    const key = `saved_${menuId || extraId}_${region}`;
    const saved = store.getItem(key);
    if (saved) return saved;
    const { data, error } = await get(
      addParamsToUrl(urls.business.menu.cost, {
        region,
        menuId: menuId || "",
        extraId: extraId || "",
      })
    );
    if (error) return null;
    setPrice(data.cost);
    store.setItem(key, data.cost);
    return data.cost;
  };

  return {
    price,
    loading,
    getCost,
  };
}
export default useMenu;
